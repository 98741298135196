import React from "react"
import ReCAPTCHA from "react-google-recaptcha"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"
import axios from "axios"
import $ from "jquery"
import { navigate } from "@reach/router"

class PartnerWithUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: null,
      email: null,
      phone: null,
      city: null,
      captcha: false,
    }

    this.captchaOnSuccess = this.captchaOnSuccess.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.onClickHandler = this.onClickHandler.bind(this)
  }

  captchaOnSuccess() {
    this.setState({ captcha: !this.state.captcha })
  }
  handleInputChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }
  onClickHandler = event => {
    if (this.state.captcha) {
      event.preventDefault()
      const data = new FormData()
      data.append("Name", this.state.name)
      data.append("Email", this.state.email)
      data.append("Phone", this.state.phone)
      data.append("City", this.state.city)
      data.append("_next", "/mobile/thank-you.html")
      data.append("_cc", "drkaloti@thesmilestylist.ca,press@thesmilestylist.ca")
      axios
        .post("https://formspree.io/meqqgblb", data, {
          // receive two    parameter endpoint url ,form data
        })
        .then(() => {
          navigate("/thank-you")
        })
    } else {
      event.preventDefault()
      document.querySelector(".captcha").scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      })
      document.querySelector(".captcha").classList += " captcha_highlight"
    }
  }
  render() {
    return (
      <Layout>
        <SEO
          title="Partner With Us | Bring The Smile Stylist To Your City"
          description="If you’re the top cosmetic specialist in your city with a reputation for delivering results and building a strong patient report, you could be the next Smile Stylist."
          keywords="partner, braces canada, dental implants, teeth canada, dental clinics, oakville, toronto, mississauga, canada"
        />
        <main className="site-content" role="main">
          <section
            className="has-image-right_partner2 _pad_bottom"
            id="main-white"
          >
            <div className="container _pad">
              <div className="row">
                <div className="col-sm-12 wow animated fadeInRight">
                  <div className="row">
                    <div className="col-md-8 col-sm-12 wow animated fadeInRight">
                      <h1 className="info__heading_small">
                        Dentists, do you think you have what it takes to
                        <br />
                        become the next Smile Stylist?
                      </h1>
                      <ul className="info__text_list-circle">
                        <li className="info__info info__info-list">Ottawa</li>
                        <li className="info__info info__info-list">Montreal</li>
                        <li className="info__info info__info-list">
                          Vancouver
                        </li>
                        <li className="info__info info__info-list">Winnepeg</li>
                        <li className="info__info info__info-list">Calgary</li>
                        <li className="info__info info__info-list">Edmonton</li>
                        <li className="info__info info__info-list">Halifax</li>
                      </ul>
                      <p className="info__info info__info-para">
                        We are looking to bring our smiles across Canada
                        <br />
                        and are searching for the most passionate, dedicated
                        <br />
                        and talented dentists in our country.
                      </p>
                    </div>
                    <div className="col-md-4 hidden-sm"></div>
                  </div>
                </div>
              </div>
              <div className="row _second ">
                <div className="col-sm-12 col-md-4 wow animated fadeInRight">
                  <img
                    className="info__img-left"
                    src={require("../images/desktop/dentist.png")}
                    alt="Dentist"
                  />
                </div>
                <div className="col-sm-12 col-md-8 form-container wow animated fadeInRight">
                  <p className="info__heading_blue">
                    For more information on partnering with the
                    <span className="info__heading_img-right">
                      <img
                        src={require("../images/desktop/ss-small.png")}
                        alt="Partner With Smile Stylist"
                      />
                    </span>
                  </p>
                  <p className="info__heading_underlined">Contact Us</p>
                  <div className="row">
                    <form
                      action="https://formspree.io/meqqgblb"
                      method="post"
                      className="info__form"
                    >
                      <input type="hidden" name="_next" value="/thank-you" />
                      <input
                        type="hidden"
                        name="_cc"
                        value="drkaloti@thesmilestylist.ca,press@thesmilestylist.ca"
                      />
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="row">
                            <input
                              onChange={this.handleInputChange}
                              className="info__field"
                              placeholder="Name"
                              type="text"
                              name="name"
                            />
                          </div>
                          <div className="row">
                            <input
                              onChange={this.handleInputChange}
                              className="info__field"
                              placeholder="Email"
                              type="email"
                              id="mail"
                              name="email"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="row">
                            <input
                              onChange={this.handleInputChange}
                              className="info__field"
                              placeholder="City"
                              type="text"
                              name="city"
                            />
                          </div>
                          <div className="row">
                            <input
                              onChange={this.handleInputChange}
                              className="info__field"
                              placeholder="Phone"
                              type="phone"
                              id="mail"
                              name="phone"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <ReCAPTCHA
                          className="captcha"
                          sitekey="6Ldyns0UAAAAAEsOzuby5eySa1QgoPCUvtWN0vsp"
                          onChange={this.captchaOnSuccess}
                        />
                        <button
                          onClick={this.onClickHandler}
                          className="info__form-btn _red__loading _w-157"
                          type="submit"
                        >
                          send
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="info__line-after">
              Help Bring the Smile Stylist to Your City.
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default PartnerWithUs
